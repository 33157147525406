.single-brand-item {
  width: 170px;
  height: 100px;
  cursor: pointer;
  mix-blend-mode: multiply;
  margin-bottom: 30px;
  border: 1px solid #eeeeee;
  box-shadow: none;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}
@media only screen and (max-width: 767px) {
  .single-brand-item {
    height: 100px;
    width: 140px;
  }
}
@media (max-width: 575px) {
  .single-brand-item {
    margin: 0px auto;
    margin-bottom: 30px;
  }
}
.single-brand-item .d-table-cell {
  vertical-align: middle;
}
.single-brand-item img {
  max-width: 80px;
  margin: 0 auto;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}
@media only screen and (max-width: 767px) {
  .single-brand-item img {
    max-width: 100px;
  }
}
.single-brand-item:hover {
  border: 1px solid transparent;
  box-shadow: 0px 8px 30px rgba(118, 85, 225, 0.15);
}
.single-brand-item:hover img {
  // filter: brightness(0%);
  // -o-filter: brightness(0%);
  // -ms-filter: brightness(0%);
  // -moz-filter: brightness(0%);
  // -webkit-filter: brightness(0%);
}

.client-info {
  padding: 25px 30px;
  background: #faf8ff;
  text-align: center;
  position: relative;
  margin-top: 25px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}
@media only screen and (max-width: 991px) {
  .client-info {
    margin-top: 50px;
  }
}
@media (max-width: 575px) {
  .client-info {
    padding-top: 50px;
  }
}
.client-info:before {
  content: "";
  position: absolute;
  top: -25px;
  left: -50px;
  // background: url("../../../public/assets/amazon.png") no-repeat;
  height: 175px;
  width: 175px;
}
@media (max-width: 575px) {
  .client-info:before {
    background: none;
  }
}
@media (max-width: 575px) {
  .client-info .d-flex {
    -ms-flex-align: center;
    align-items: center;
  }
}
.client-info .lage {
  color: #854fee;
  font-family: "Rubik", sans-serif;
  font-size: 100px;
  font-weight: 700;
  line-height: 85px;
  position: relative;
  z-index: 9;
}
.client-info .smll {
  text-align: left;
  color: #000000;
  font-family: "Rubik", sans-serif;
  font-size: 20px;
  margin-left: 20px;
}

.call-now {
  padding-top: 12px;
  text-align: left;
  // margin-bottom: 40px;
}
@media (max-width: 575px) {
  .call-now {
    padding-top: 0px;
  }
}
@media (max-width: 480px) {
  .call-now h3 {
    font-size: 17px;
  }
}
.call-now p {
  color: #854fee;
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 5px;
}
.call-now span {
  font-size: 60px;
  font-weight: 700;
  color: #000000;
}
